<template>
  <div class="withdraw">
    <Header title="提现" rightTitle="提现明细" path="/withdrawDetails" />
    <div class="main">
      <div class="wallet-balance-box">
        <div class="wallet-title">
          钱包余额
          <span>(游戏币)</span>
        </div>
        <div class="wallet-balance">{{ $store.getters.userInfo.amount }}</div>
      </div>
      <div class="cellItem withdrawType">
        <div class="cellTitle">提现种类:</div>
        <div class="cellContent">人民币</div>
      </div>
      <div class="cellItem">
        <div class="cellTitle">提现金额:</div>
        <van-field
          class="cellContent vantInput"
          :center="true"
          v-model="withdrawAmount"
          :border="false"
          :placeholder="`单笔提现金额范围: ${minMoney}-${maxMoney}元`"
          @input="changeAmount"
        >
          <template slot="left-icon">
            <span>¥</span>
          </template>
        </van-field>
      </div>
      <div class="cellItem">
        <div class="cellTitle">提现方式:</div>
        <van-radio-group v-model="withdrawTypeIndex" direction="horizontal" class="cellContent radioGroup">
          <div class="radioItem" v-for="(item, index) in withdrawTypes" :key="index" @click="changeWIthdrawType(index)">
            <svg-icon class="withdrawIcon" :iconClass="item.payType"></svg-icon>
            <div>{{ formatWithdrawType(item.payType) }}</div>
            <van-radio checked-color="#fe7f0f" icon-size="24px" :name="index">
              <template #icon="props">
                <img class="imgIcon" :src="props.checked ? activeIcon : inactiveIcon" />
              </template>
            </van-radio>
          </div>
        </van-radio-group>
      </div>
      <!-- 银行卡 -->
      <div class="cellItem" v-if="withdrawType == 'bankcard'">
        <div class="cellTitle">开户姓名:</div>
        <van-field class="cellContent vantInput" :center="true" v-model="actName" :border="false" placeholder="请输入开户姓名"></van-field>
      </div>
      <div class="cellItem" v-if="withdrawType == 'bankcard'">
        <div class="cellTitle">银行卡号:</div>
        <!-- @blur="checkBankCard" -->
        <van-field
          class="cellContent vantInput"
          :center="true"
          v-model="act"
          :border="false"
          placeholder="请输入银行卡号"
        ></van-field>
      </div>
      <!-- USDT -->
      <div class="cellItem" v-if="withdrawType == 'usdt'">
        <div class="cellTitle">钱包地址:</div>
        <van-field class="cellContent vantInput" :center="true" v-model="act" :border="false" placeholder="请输入钱包地址"></van-field>
      </div>
      <div class="cellItem warmTip" v-if="withdrawType == 'usdt'">
        <div class="cellTitle">温馨提示:</div>
        <div class="cellContent handlingFee">请您务必仔细核对收款地址信息后再做提交，避免给您带来不便。</div>
      </div>
      <div class="cellItem warmTip">
        <div class="cellTitle">手续费率:</div>
        <div class="cellContent handlingFee">
          {{ `${handlingFee}% 本次提现手续费：${formalitiesAmount}元` }}
          <br />
          {{ `实际到账金额：${realAmount}元` }}
        </div>
      </div>
      <div class="btnBox">
        <div class="tip">
          提现将在1-2工作日内到账，如未收到，请联系
          <router-link tag="span" to="/kefu">在线客服</router-link>
        </div>
        <div class="withdrawBtn" @click="withdraw">立即提现</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Loading, RadioGroup, Radio, Toast } from 'vant';
import { queryWithdrawCfg, withdraw, checkBankCard } from '@/api/mine';
import { mapGetters } from 'vuex';
import Header from '@/components/Header';

export default {
  name: 'Withdraw',
  components: {
    [Field.name]: Field,
    [Loading.name]: Loading,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    Header,
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
  },
  data() {
    return {
      withdrawType: '',
      withdrawTypeIndex: 0, // 提现方式下标
      withdrawAmount: '',
      act: '', // 交易账户
      actName: '', // 交易账户持有人
      bankCode: '', // 银行卡编号
      balance: 0, // 余额
      handlingFee: 0, // 手续费
      formalitiesAmount: 0, //手续费金额
      realAmount: 0, // 实际到账金额
      cid: '', // 渠道ID
      withdrawTypes: [], // 提现类型列表
      minMoney: 0, // 单笔提现最小金额
      maxMoney: 0, // 单笔提现最大金额
      withdrawPlaceholder: '单笔提现金额范围: 1-100元', //提现提示
      activeIcon: require('@/assets/png/withActiveIcon.png'),
      inactiveIcon: require('@/assets/png/withInActiveIcon.png'),
    };
  },
  created() {
    this.balance = this.userInfo.amount;
    this.queryWithdrawCfg();
  },
  methods: {
    // 查询余额
    // async queryBalance() {
    //   await this.$store.dispatch('user/getWallet');
    //   let walletInfo = this.$store.getters.walletInfo;
    //   this.balance = walletInfo.gameAmount;
    // },
    // 查询提现配置
    async queryWithdrawCfg() {
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(queryWithdrawCfg);
        this.$store.commit('app/SET_LOADING', false);
        if (res && res.code == 200) {
          this.withdrawTypes = res.data.channels;
          this.handlingFee = res.data.gameTax;
          this.changeWIthdrawType(0);
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('查询失败，请重新进入');
      }
    },
    // 改变提现方式
    changeWIthdrawType(index) {
      this.act = '';
      this.actName = '';
      let typeList = this.withdrawTypes;
      this.withdrawTypeIndex = index;
      this.cid = typeList[index].cid;
      this.withdrawType = typeList[index].payType;
      this.minMoney = typeList[index].qpMinMoney / 100;
      this.maxMoney = typeList[index].qpMaxMoney / 100;
    },
    // 计算金额
    changeAmount() {
      let money = this.$store.getters.userInfo.amount;
      if (this.withdrawAmount > money) {
        Toast('提现金额大于自身拥有金额');
      }
      this.formalitiesAmount = ((this.withdrawAmount * this.handlingFee) / 100).toFixed(2);
      this.realAmount = (this.withdrawAmount - this.formalitiesAmount).toFixed(2);
    },
    // 提现类型名称
    formatWithdrawType(type) {
      let str = '';
      switch (type) {
        case 'alipay':
          str = '支付宝';
          break;
        case 'bankcard':
          str = '银行卡';
          break;
        case 'usdt':
          str = 'USDT';
          break;
        default:
          break;
      }
      return str;
    },
    // 校验银行卡
    async checkBankCard() {
      let req = {
        _input_charset: 'utf-8',
        cardBinCheck: true,
        cardNo: this.act,
      };
      if (!this.act) return;
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(checkBankCard, req);
        
        if (res && res.validated && res.bank && res.cardType) {
          this.bankCode = res.bank;
        } else {
          this.$store.commit('app/SET_LOADING', false);
          this.bankCode = '';
          // Toast('请输入正确的银行卡号');
        }
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('网络错误，请稍后再试');
      }
    },
    // 提现
    async withdraw() {
      let money = this.$store.getters.userInfo.amount;

      if (!this.withdrawAmount) {
        Toast('请输入提现金额');
        return;
      }

      if (this.withdrawAmount > money) {
        Toast('提现金额大于自身拥有金额');
        return;
      }
      if (this.withdrawAmount > this.maxMoney) {
        Toast(`单笔提现金额不大于${this.maxMoney}`);
        return;
      }
      if (this.withdrawAmount < this.minMoney) {
        Toast(`单笔提现金额不小于${this.minMoney}元`);
        return;
      }
      if (!this.actName && this.withdrawType != 'usdt') {
        Toast(this.withdrawType == 'alipay' ? '请输入支付宝' : '请输入开户姓名');
        return;
      }
      if (!this.act) {
        Toast(
          this.withdrawType == 'alipay' ? '请输入支付宝账号' : (this.withdrawType == 'alipay') == 'bankcard' ? '请输入银行卡号' : '请输入钱包地址',
        );
        return;
      }
      if (this.withdrawType == 'bankcard') {
        await this.checkBankCard()
      }
      if (this.withdrawType == 'bankcard' && !this.bankCode) {
        Toast('请输入正确的银行卡号');
        return;
      }

      let req = {
        name: this.userInfo.name, // 用户名
        money: this.withdrawAmount * 100, // 分
        payType: this.withdrawType, // alipay，bankcard，usdt
        withdrawType: 4, // 0: 代理提现   1: 金币提现 2: 商区提现 3: 财务转账提现 4: 游戏提现
        actName: this.actName, // 提现姓名
        bankCode: this.bankCode, // 银行卡Code
        act: this.act, // 提现账号
        cid: this.cid, // 渠道ID
        devID: this.$store.getters.UID,
        productType: 1, // 0: 站群  1: 棋牌
      };
      this.$store.commit('app/SET_LOADING', true);
      try {
        let ret = await this.$Api(withdraw, req);
        this.$store.commit('app/SET_LOADING', false);
        if (ret && ret.code === 200) {
          this.$store.dispatch('user/getUserInfo');
          Toast('已提交申请');
          this.withdrawAmount = '';
          this.$router.push('/withdrawDetails');
        } else if (ret.tip) {
          Toast(ret.tip || '提现失败');
        }
      } catch (error) {
        Toast('操作失败');
        this.$store.commit('app/SET_LOADING', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.withdraw {
  height: 100%;
  width: 100%;
  background-color: #fff;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 40px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 40px;
      text-align: center;
    }
  }
  .main {
    padding: 11px 12px;
    .wallet-balance-box {
      background-color: rgb(255, 230, 221);
      border-radius: 12px;
      height: 106px;
      padding: 17px 22px;
      box-sizing: border-box;
      .wallet-title {
        font-size: 16px;
        font-weight: 500;
        color: rgb(51, 51, 51);
        letter-spacing: 0.32px;
        span {
          font-size: 12px;
          font-weight: 400;
          color: rgb(24, 24, 24);
        }
      }
      .wallet-balance {
        font-size: 36px;
        letter-spacing: 0.72px;
        color: rgb(247, 78, 130);
        text-align: center;
        line-height: 50px;
      }
    }
    .cellItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .cellTitle {
        width: 72px;
        margin-right: 2px;
        font-size: 16px;
      }

      .cellContent {
        margin-top: 3px;
        flex: 2;
        // line-height: 24px;
      }
      .handlingFee {
        font-size: 13px;
        color: rgb(153, 153, 153);
      }

      .vantInput {
        height: 42px;
        border-radius: 6px;
        background: rgb(246, 246, 246);
        span {
          font-size: 20px;
          display: inline-block;
          margin-top: 2px;
        }
        /deep/ .van-field__control {
          font-size: 12px;
          color: #000;
        }

        /deep/ .van-field__control {
          font-size: 14px;
        }

        ::placeholder {
          color: #939bac;
        }
      }

      .radioGroup {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 16px;
        // grid-column-gap: 6px;

        .radioItem {
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex: 1;
          font-size: 16px;
          .van-radio__icon {
            height: unset;
          }
          .withdrawIcon {
            width: 39px;
            height: 39px;
          }
          .imgIcon {
            height: 24px;
            width: 24px;
          }
        }
      }
    }
    .withdrawType {
      color: rgb(122, 114, 129);
      margin-top: 10px;
    }
    .warmTip {
      align-items: flex-start;
    }
    .btnBox {
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 40px;
      z-index: 2;

      .withdrawBtn {
        width: 271px;
        height: 49px;
        line-height: 49px;
        border-radius: 10px;
        font-size: 16px;
        text-align: center;
        margin: 0 auto;
        color: #fff;
        background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
      }

      .tip {
        font-size: 12px;
        margin-bottom: 10px;
        text-align: center;
        color: rgb(153, 153, 153);

        span {
          color: rgb(255, 103, 143);
        }
      }
    }
  }
}
</style>
